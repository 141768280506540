import _clone from 'lodash.clonedeep'
import _debounce from 'lodash.debounce'
import _merge from 'lodash.merge'

import { groupProductsByProperty } from '@/helpers/assortmentDataModifiers'
import { arrayModifiers } from '@/helpers/dataTypeModifiers'
import { properties } from '@/store/constants/config/properties'

import {
  VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD_INIT,
  VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD,
  VUEX_GROUPSORT_ASSORTMENT_UPDATE,
  VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE,
  VUEX_GROUPSORT_ASSORTMENT_CLEAR_BATCHED_REQUESTS,

  VUEX_GROUPSORT_ASSORTMENT_SAVE_LINKED_ASSORTMENT,

  VUEX_GROUPSORT_ASSORTMENT_UPDATE_PROPS,
  VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT,
  VUEX_GROUPSORT_SORTORDER_UPDATE,

  VUEX_GROUPSORT_SET_GROUPBY,
  VUEX_GROUPSORT_SET_AUTOSORT,

  VUEX_GROUPSORT_ITEMS_AUTOSORT
} from '@/store/constants/ui/groupSort'

import {
  VUEX_ASSORTMENT_UPDATE,
  VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT
} from '@/store/constants/models/assortments'

import {
  VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT
} from '@/store/constants/models/assortments/assortmentGroupSort'

import {
  VUEX_API_ASSORTMENT_GROUPSORT_UPDATE
} from '@/store/constants/api'

const GROUP_SORT_DEFAULTS = properties.GroupSort
const UPDATE_DELAY = 500

const displayGroupUncategorizedValue = groupBy => {
  if (!groupBy) return { title: null, value: null }

  const defaultUncatValue = 'Uncategorized'
  return {
    title: defaultUncatValue,
    value: groupBy.value === 'none' ? null : defaultUncatValue
    // value: defaultUncatValue
  }
}

const state = {
  displayGroups: [],

  groupBy: null,
  autoSort: [],

  batchRequests: [],

  linkedAssortment: {
    assortmentId: null
  }
}

const getters = {
  displayGroupByName: state => name => {
    if (!name) return {}

    const groupIndex = state.displayGroups.findIndex(group => group.displayGroup?.toString().toLowerCase() === name?.toString().toLowerCase())
    const groupData = state.displayGroups[groupIndex]

    return {
      index: groupIndex,
      data: groupData
    }
  }
}

const actions = {
  // Fetch assortment data
  // Build Group & Sort data structure
  [VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD]: async ({ rootState, state, dispatch, commit }, payload) => {
    // Get and clone loaded assortment data for products
    const assortmentData = _clone(rootState.assortments.assortment)

    // IF: assortment doesn't contain products: bail
    if (!assortmentData?.products?.length) {
      commit( )
      // return
    }

    // Set orgType var
    const orgType = assortmentData.orgType
    if (assortmentData._id !== state.linkedAssortment.assortmentId) {
      await commit(VUEX_GROUPSORT_ASSORTMENT_SAVE_LINKED_ASSORTMENT, { assortmentId: assortmentData._id })
    }

    // Set groupBy
    const assortmentGroupBy = assortmentData.uiSettings?.groupSettings?.groupBy
    let groupBy = state.groupBy || assortmentGroupBy || GROUP_SORT_DEFAULTS[orgType].groupBy

    // Update groupBy on initial load | manualRefresh = false
    if (!payload?.manualRefresh) await commit(VUEX_GROUPSORT_SET_GROUPBY, groupBy)

    // Group products by state.groupBy.value | state.groupBy -> 'pillar'
    const groupedProducts = groupProductsByProperty(assortmentData.products, groupBy.value)

    // **********************
    // Compute group data
    // **********************
    let groupsData = []
    groupedProducts.forEach((group, index) => {
      // Set base product to use when saving common data to group
      const product = group[0]
      // Set common displayGroup name -> use value defined in groupBy || use conditional "Uncategorized" value / null
      let displayGroup = product[groupBy.value] || displayGroupUncategorizedValue(groupBy).value

      let displayGroupTitle = displayGroup?.toString()

      // Update title/props for specific groupBy values
      switch (groupBy.value) {
        case 'division' :
          const storeProps = rootState.properties.data.Product.properties[groupBy.value].options
          const matchObj = storeProps.find(prop => prop.value === displayGroup)
          displayGroupTitle = matchObj?.label || displayGroupUncategorizedValue(groupBy).value
          break
      }

      // Get default data obj from state
      let data = _clone(GROUP_SORT_DEFAULTS.displayGroup)

      // Set index for VueDraggable
      data.index = index
      // Add displayGroup to root element
      data.displayGroup = displayGroup
      data.title = displayGroupTitle

      // Per Brendan's Postman example, add displayGroup to customContent
      data.customContent.displayGroup = displayGroup
      const customContentData = assortmentData.customContent && assortmentData.customContent.find(content => content.displayGroup?.toString().toLowerCase() === displayGroup?.toString().toLowerCase())
      if (customContentData) data.customContent = { ...customContentData }

      // Check if all colors in the group have the same displayGroup name
      const allColorsHaveDisplayGroup = group.every(color => color.displayGroup?.toString().toLowerCase() === displayGroup?.toString().toLowerCase())
      if (!allColorsHaveDisplayGroup/* || !displayGroup || displayGroup === 'Uncategorized' */) {
        dispatch(VUEX_ASSORTMENT_PRODUCTS_UPDATE_FROM_GROUPSORT, {
          products: group,
          props: { displayGroup: displayGroup?.toString() }
        })
      }

      // Group colors by style
      const styleGroupedColors = groupProductsByProperty(group, 'style', { displayGroup: displayGroup })

      // Loop over grouped color to add style level props
      styleGroupedColors.forEach((colorGroup, styleIdx) => {
        data.styles.push({
          displayGroup,
          style: colorGroup[0].style,
          color: colorGroup[0].color,
          productType: colorGroup[0].productType,
          origIndex: styleIdx,
          colors: colorGroup
          // thumbnail: colorGroup[0].thumbnail
        })
      })

      if (!displayGroup || displayGroup === 'Uncategorized') {
        data.locked = true
        data.alwaysOpen = true
        data.openState = true
      }

      groupsData.push(data)
    })

    // Commit data to state
    await commit(VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD, groupsData)

    // **********************************************
    // Move Uncategorized to end of the array
    let uncatIdx = groupsData.findIndex(group => group.displayGroup === displayGroupUncategorizedValue(groupBy).value)
    let lastIndex = groupsData.length - 1

    if (uncatIdx !== lastIndex && uncatIdx > -1 && lastIndex > -1) {
      await dispatch(VUEX_GROUPSORT_SORTORDER_UPDATE, {
        sortType: 'displayGroup',
        oldIndex: uncatIdx,
        newIndex: lastIndex,
        data: groupsData[uncatIdx]
      })
    }
    // **********************************************
  },

  [VUEX_GROUPSORT_ASSORTMENT_UPDATE]: _debounce(async ({ rootState, state, commit, dispatch }, payload) => {
    const assortment = rootState.assortments.assortment

    const clonedBatchedRequests = payload ? _clone(payload) : _clone(state.batchRequests)
    await commit(VUEX_GROUPSORT_ASSORTMENT_CLEAR_BATCHED_REQUESTS)

    const orgType = assortment.orgType === ITS__ASSORTMENTS__ORG_TYPE__INTERNAL
      ? 'internalAssortments'
      : 'assortments'

    await dispatch(VUEX_API_ASSORTMENT_GROUPSORT_UPDATE, {
      orgType,
      id: assortment._id,
      data: clonedBatchedRequests
    }).then(response => {
      // commit(VUEX_GROUPSORT_SORTORDER_UPDATE)
    })
  }, UPDATE_DELAY),

  [VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE]: async ({ commit, dispatch }, payload) => {
    await commit(VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE, payload)
    dispatch(VUEX_GROUPSORT_ASSORTMENT_UPDATE)
  },

  [VUEX_GROUPSORT_SORTORDER_UPDATE]: async ({ commit, dispatch }, payload) => {
    if (typeof payload !== 'object') {
      console.error(VUEX_GROUPSORT_SORTORDER_UPDATE, 'payload not [Array, Object]')
      return
    }

    // IF: payload is not an Array (is Object): wrap payload in array
    if (!Array.isArray(payload)) payload = [payload] || []

    let batchedObj = {}
    payload.forEach(async item => {
      const groupIdx = state.displayGroups.findIndex(group => group.displayGroup?.toString().toLowerCase() === item.data.displayGroup?.toString().toLowerCase())
      const styleIdx = state.displayGroups[groupIdx].styles.findIndex(style => style.style === item.data?.style)

      let actionType = ''

      await commit(VUEX_GROUPSORT_SORTORDER_UPDATE, {
        ...item, groupIdx, styleIdx
      })

      switch (item.sortType) {
        case 'displayGroup' :
          actionType = 'moveDisplayGroup'
          if (!batchedObj[actionType]) batchedObj[actionType] = []

          batchedObj[actionType].push({
            item: state.displayGroups[item.newIndex]?.displayGroup?.toString() || null,
            beforeItem: state.displayGroups[++item.newIndex]?.displayGroup?.toString() || null
          })
          break
        case 'style' :
          actionType = 'moveStyle'
          if (!batchedObj[actionType]) batchedObj[actionType] = []
          const styles = state.displayGroups[groupIdx].styles

          // ----------------------------------
          // Special handling for sorting styles
          const beforeItem = styles[item.newIndex + 1]?.style // next item exists?
            ? styles[item.newIndex + 1]?.style // use next item
            : groupIdx !== state.displayGroups.length - 1 // current group is NOT last in group?
              ? state.displayGroups[groupIdx + 1].styles[0].style // get fist style of next group
              : null // if current group is last, set null
          // ----------------------------------

          batchedObj[actionType].push({
            item: styles[item.newIndex]?.style || null,
            beforeItem: beforeItem
            // beforeItem: styles[++item.newIndex]?.style || null
          })
          break
        case 'color' :
          actionType = 'moveColor'
          if (!batchedObj[actionType]) batchedObj[actionType] = []
          const style = state.displayGroups[groupIdx]?.styles[styleIdx]
          const colors = style?.colors

          batchedObj[actionType].push({
            style: style.style,
            item: colors[item.newIndex]?.color || null,
            beforeItem: colors[++item.newIndex]?.color || null
          })
          break
      }
    })

    dispatch(VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE, batchedObj)
  },

  [VUEX_GROUPSORT_ASSORTMENT_UPDATE_PROPS]: ({ getters, commit, dispatch }, payload) => {
    if (!payload.displayGroup) {
      console.error('VUEX: VUEX_GROUPSORT_ASSORTMENT_UPDATE_PROPS: payload missing `displayGroup`')
    }

    Object.keys(payload.props).forEach(key => {
      switch (key) {
        case 'customContent' : dispatch(VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT, {
          displayGroup: payload.displayGroup?.toString(),
          data: payload.props[key]
        }); break
      }
    })

    const displayGroup = getters.displayGroupByName(payload.displayGroup)
    commit(VUEX_GROUPSORT_ASSORTMENT_UPDATE_PROPS, {
      ...payload,
      displayGroup
    })
  },

  [VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT]: async ({ commit, dispatch }, payload) => {
    dispatch(VUEX_ASSORTMENT_UPDATE_CUSTOM_CONTENT, payload)
    commit(VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT, payload)
  },

  [VUEX_GROUPSORT_SET_GROUPBY]: async ({ rootState, state, commit, dispatch }, payload) => {
    await commit(VUEX_GROUPSORT_SET_GROUPBY, payload)

    const assortmentUISettings = _clone(rootState.assortments.assortment.uiSettings) || {}
    const properties = { properties: { uiSettings: _merge(assortmentUISettings, { groupSettings: { groupBy: state.groupBy } }) } }

    dispatch(VUEX_ASSORTMENT_UPDATE, properties) // <------------------- //////// ADDING AWAIT WILL NOT GROUP ITEMS UNTIL REQUEST HAS FINISHED //////////
    // await commit(VUEX_ASSORTMENT_UPDATE_SUCCESS, properties)

    dispatch(VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD, { manualRefresh: true })
  },

  [VUEX_GROUPSORT_SET_AUTOSORT]: async ({ state, commit, dispatch }, payload) => {
    await commit(VUEX_GROUPSORT_SET_AUTOSORT, payload)

    await commit(VUEX_GROUPSORT_ITEMS_AUTOSORT)

    const sortStyles = state.displayGroups.flatMap(group => group.styles.map(item => item.style))
    dispatch(VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE, { sortStyles })
    // dispatch(VUEX_ASSORTMENT_UPDATE, [sortStyles]) // // <------------------- //////// TODO: IS UPDATING FE NECESSARY //////////
  }
}

const mutations = {
  [VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD_INIT]: state => {
    state.displayGroups = []
  },

  [VUEX_GROUPSORT_ASSORTMENT_DATA_LOAD]: (state, data) => {
    state.displayGroups = data
  },

  [VUEX_GROUPSORT_ASSORTMENT_SAVE_LINKED_ASSORTMENT]: (state, data) => {
    state.linkedAssortment = data
    state.groupBy = null
    state.autoSort = []
  },

  [VUEX_GROUPSORT_SORTORDER_UPDATE]: (state, data) => {
    const groupIdx = data.groupIdx
    const styleIdx = data.styleIdx

    switch (data.sortType) {
      case 'displayGroup' :
        state.displayGroups = arrayModifiers.move(state.displayGroups, data.oldIndex, data.newIndex)
        break
      case 'style' :
        state.displayGroups[groupIdx].styles = arrayModifiers.move(state.displayGroups[groupIdx].styles, data.oldIndex, data.newIndex)
        break
      case 'color' :
        state.displayGroups[groupIdx].styles[styleIdx].colors = arrayModifiers.move(state.displayGroups[groupIdx].styles[styleIdx].colors, data.oldIndex, data.newIndex)
        break
    }
  },

  [VUEX_GROUPSORT_ASSORTMENT_BATCH_UPDATE]: (state, data) => {
    state.batchRequests.push(data)
  },

  [VUEX_GROUPSORT_ASSORTMENT_CLEAR_BATCHED_REQUESTS]: state => {
    state.batchRequests = []
  },

  [VUEX_GROUPSORT_ASSORTMENT_UPDATE_PROPS]: (state, data) => {
    const index = data.displayGroup.index
    state.displayGroups[index] = _merge(state.displayGroups[index], data.props)
  },

  [VUEX_GROUPSORT_ASSORTMENT_UPDATE_CUSTOM_CONTENT]: (state, data) => {
    const displayGroup = data.item.displayGroup
    const groupIdx = state.displayGroups.findIndex(group => group.displayGroup?.toLowerCase() === displayGroup?.toLowerCase())

    Object.assign(state.displayGroups[groupIdx].customContent, data.data)
  },

  [VUEX_GROUPSORT_SET_GROUPBY]: (state, data) => {
    state.groupBy = data
  },

  [VUEX_GROUPSORT_SET_AUTOSORT]: (state, data) => {
    state.autoSort = data
  },

  [VUEX_GROUPSORT_ITEMS_AUTOSORT]: state => {
    if (state.autoSort.length) {
      const key0 = state.autoSort[0]?.value
      const key1 = state.autoSort[1]?.value
      const key2 = state.autoSort[2]?.value

      state.displayGroups.forEach(group => {
        let sortedStyles = _clone(group.styles)

        sortedStyles.sort((a, b) => {
          let a0 = String(a.colors[0][key0])
          let b0 = String(b.colors[0][key0])

          let a1 = String(a.colors[0][key1])
          let b1 = String(b.colors[0][key1])

          let a2 = String(a.colors[0][key2])
          let b2 = String(b.colors[0][key2])

          return a0.localeCompare(b0, 'en', { numeric: key0 === 'styleNumeric' }) ||
                 a1.localeCompare(b1, 'en', { numeric: key1 === 'styleNumeric' }) ||
                 a2.localeCompare(b2, 'en', { numeric: key2 === 'styleNumeric' }) ||
                 a.origIndex - b.origIndex
        })

        group.styles = sortedStyles
      })
    }
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
